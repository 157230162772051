import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Typography from '@mui/material/Typography';
import { downloadCourierBill, upload, searchCourierBillsAPICall, deleteCourierBillAPICall, deleteAllBillsAPICall, deleteAllBillsForUserIECNoAPICall, downloadBillsWithLimit } from '../services/Service'
import { ResponsiveDialog, PasswordDialogBox, CheckboxConfirmationDialogBox} from './Dialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { BasicAlert } from './Error';
import { useNavigate, useLocation } from 'react-router-dom';



export default function CourierBills() {

    const [showClear, setShowClear] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(1);
    const [billsList, setBillsList] = React.useState([]);
    const [selectedBills, setSelectedBills] = React.useState([]);
    const [file, setFile] = React.useState(null);
    const [showWarning, setShowWarning] = React.useState({display: "none"});
    const [emailValidationMsg, setEmailValidationMsg] = React.useState();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [openProgress, setOpenProgress] = React.useState(false);
    const [isSort, setIsSort] = React.useState(false);
    const [totalBillsCount, setTotalBillsCount] = React.useState(0);
    const [exportRangeType, setExportRangeType] = React.useState("null");
    const [exportRangeSelectSkipCount, setExportRangeSelectSkipCount] = React.useState(-1);
    const [exportRangeLimitCount, setExportRangeLimitCount] = React.useState(0);
    const [passwordBoxOpen, setPasswordBoxOpen] = React.useState(false);
    const [checkboxConfirmationBoxOpen, setCheckboxConfirmationBoxOpen] = React.useState(false);
    const [deleteAllPassword, setDeleteAllPassword] = React.useState("");
    const [checkboxConfirmationTick, setCheckboxConfirmationTick] = React.useState(false);
    const [searchDetails, setSearchDetails] = React.useState({
        cb_no: null,
        iec_br: null,
        startDate: null,
        endDate: null
    });

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    };

    const handleToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };

    const handleProgressOpen = () => {
        setOpenProgress(true);
    };

    const handleProgressClose = () => {
        setOpenProgress(false);
    };

    const handlePasswordInputChange = (e) => {
        const password = e.target.value;
        setDeleteAllPassword(password);
    }

    const handleCheckboxInputChange = (e) => {
        const isChecked = e.target.checked;
        console.log(isChecked);
        setCheckboxConfirmationTick(isChecked);
    }

    const handleCheckboxConfirmationDialogBoxClose = (permissionToDel) => {
        if (permissionToDel) {
            const token = localStorage.getItem('token');
            if (checkboxConfirmationTick){
                setCheckboxConfirmationBoxOpen(false);
                console.log("DELETE ALL for an IEC");
                const billType = "Courier";
                handleToggle();
                deleteAllBillsForUserIECNoAPICall(token, billType).then(response => {
                    if (response){
                        window.location.reload();
                    } else{
                        console.log("Error :: response = null");
                    }
                    handleBackdropClose();
                }, error => {
                    handleBackdropClose();
                    console.log("Error response: ", error.response)
                    if (error.response){
                        if (error.response.status === 403){
                            // localStorage.clear();
                            // window.location.href = "/"
                        }
                    }
                })
            }
        }
        else{
            setCheckboxConfirmationBoxOpen(false);
        }
    }

    const handlePasswordDialogBoxClose = (permissionToDelAll) => {
        const token = localStorage.getItem('token');
        if (permissionToDelAll) {

            if(deleteAllPassword.length > 0){
                console.log("Truncate table");
                // deleteAllPassword
                const billType = "Courier";
                handleToggle();
                let truncatePassword = {
                    password: deleteAllPassword,
                }
                deleteAllBillsAPICall(token, truncatePassword, billType).then(response => {
                    if (response){
                        window.location.reload();
                        handleBackdropClose();
                        
                    } else{
                        handleBackdropClose();
                        setPasswordBoxOpen(false);
                        console.log("Error :: response = null");
                    }
                }, error => {
                    handleBackdropClose();
                    setPasswordBoxOpen(false);
                    console.log("Error response: ", error.response)
                    if (error.response){
                        if (error.response.status === 403){
                            localStorage.clear();
                            window.location.href = "/"
                        }
                    }
                })
            }
            else{
                console.log("Please enter password")
            }
        }
        else {
            setPasswordBoxOpen(false);
        }
    }

    // Close dialog box after permission granted by user
    const handleClose = (permissionToDel) => {
        if (permissionToDel) {
            const token = localStorage.getItem('token');
            let deleteBillSchema = {
                bills: []
            }
            // console.log(selectedBills)
            handleToggle();
            for (var i = 0; i < selectedBills.length; i++){
                const [cb_no, iec_no, cb_date, filename] = selectedBills[i].split(";");

                const bill = {
                    cb_no: cb_no,
                    cb_date: cb_date,
                    iec_no: iec_no,
                    filename: filename
                }

                deleteBillSchema.bills.push(bill);  
            }

            deleteCourierBillAPICall(token, deleteBillSchema).then(response => {
                // console.log(response);
                if (response){
                    if (response.data){
                        
                        window.location.reload();
                    }
                    handleBackdropClose();
                    
                } else{
                    handleBackdropClose();
                    console.log("Error :: response = null");
                }
            }, error => {
                console.log("Error response: ", error.response)
                if (error.response){
                    if (error.response.status === 403){
                        localStorage.clear();
                        window.location.href = "/"
                    }
                }
            })
            
        }
        else {
            
        }
        setOpen(false);
    };

    const removeBills = () => {
        setOpen(true)

    }

    const removeAllBills = () => {
        setPasswordBoxOpen(true);
    }

    const removeBillsForAnIEC = () => {
        console.log("removeBillForAnIEC ::");
        setCheckboxConfirmationBoxOpen(true);
    }

    const addOptionsInRangeSelect = (totalRecords) => {
        var exportRangeSelect = document.getElementById("exportRangeSelect");
        
        const limit = 500;
        // console.log(totalRecords);
        if (totalRecords !== null && totalRecords !== 0){ 
            var optionsCount = parseInt((totalRecords / limit) + 1); 
            for (let i = 0; i < optionsCount; i++){
                // console.log(i);
                var skip = i * limit;
                var optionText = String(i * limit) + "-" + String((i * limit) + limit);
                // console.log(optionText)
                var option = document.createElement("option");
                option.text = optionText;
                option.value = skip;
                exportRangeSelect.add(option);
            }
        }
    }

    // Use Effect
    React.useEffect(() => {
        let mounted = true;
        const token = localStorage.getItem('token');
        let skip = 0
        
        // API calling
        handleProgressOpen();
        searchCourierBillsAPICall(token, searchDetails, skip).then(response => {
            if (mounted){
                
                if (response){
                    handleProgressClose();
                    if (response.data){
                        // console.log(response.data.data);
                        const totalRecords = response.data.count;
                        addOptionsInRangeSelect(totalRecords);
                        setTotalBillsCount(totalRecords);
                        let pagesToShow = null;
                        if (totalRecords % 50 === 0) {
                            pagesToShow = Math.floor(totalRecords / 50);
                        } else {
                            pagesToShow = Math.floor(totalRecords / 50) + 1;
                        }
                        setPageCount(pagesToShow)
                        
                        setBillsList(response.data.data);
                        handleBackdropClose();
                    } else{
                        handleBackdropClose();

                    }
                } else{
                    handleProgressClose();
                }
            }
        }, error => {
            handleProgressClose();
            console.log("Error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            } 
        });

        return () => mounted = false;
        
    }, [])


    const getRole = () => {
        const role = localStorage.getItem('role');
        return role;
    }

    // Get uploaded file
    const handleFileChange = (e) => {
        // console.log(e.target.files);
        const totalFiles = e.target.files;
        if (totalFiles.length > 25){
            alert("You cannot select more than 25 files");
            window.location.reload();
        } else{
            setFile(totalFiles);
        }   
    }

    // Upload a pdf file API
    const uploadFile = () => {
        // console.log(file)
        if (file !== null){
            // API Call
            const token = localStorage.getItem('token');
            handleToggle();
            upload(token, file, "Courier").then(response => {
                if(response){
                    // console.log(response)
                    handleBackdropClose();
                    setEmailValidationMsg(response.data.msg);
                    setShowWarning({display: "inline-block"});
                    setTimeout(() => {
                        setShowWarning({display: "none"});
                    }, 5000)
                    if (response.data.msg === "Success"){
                        // navigate("/lucrative");
                        window.location.href = "/lucrative?status=courier";
                    }
                    
                } else{
                    handleBackdropClose();
                    console.log("Error occured")
                    setEmailValidationMsg("Error");
                    setShowWarning({display: "inline-block"});
                    setTimeout(() => {
                        setShowWarning({display: "none"});
                    }, 5000)

                }
            }, error => {
                console.log("error response: ", error.response)
                if (error.response){
                    if (error.response.status === 403){
                        localStorage.clear();
                        window.location.href = "/"
                    }
                }  
            })
        } else{
            setEmailValidationMsg("No file choosen");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 5000)
        }
    }

    // Bill search inputs (CB No., CB date Range) 
    const handleInputChange = (e) => {
        const inputName = e.target.name;
        const val = e.target.value;

        if (inputName === "cb_no") {
            setSearchDetails(prevValue => {
                return {
                    cb_no: val,
                    startDate: prevValue.startDate,
                    endDate: prevValue.endDate
                }
            })
        }
        if (inputName === "iec_br") {
            setSearchDetails(prevValue => {
                return {
                    cb_no: prevValue.cb_no,
                    iec_br: val,
                    startDate: prevValue.startDate,
                    endDate: prevValue.endDate
                }
            })
        }
        if (inputName === "cb_date_start") {
            setSearchDetails(prevValue => {
                return {
                    cb_no: prevValue.cb_no,
                    startDate: val,
                    endDate: prevValue.endDate
                }

            })
        }
        if (inputName === "cb_date_end") {
            setSearchDetails(prevValue => {
                return {
                    cb_no: prevValue.cb_no,
                    startDate: prevValue.startDate,
                    endDate: val
                }
            })
        }
    }

    // Checkbox selection and diselection
    const handleSelect = (e) => {
        var checkboxes = document.getElementsByName('bill-checkboxes');
        const isSelected = e.target.checked

        if (isSelected) {
            setSelectedBills(prevValue => {
                return []
            })
        }
        for (var checkbox of checkboxes) {
            if (isSelected) {

                checkbox.checked = true
                const val = checkbox.value;
                setSelectedBills(prevValue => {
                    return [...prevValue, val]
                })

            }
            if (!isSelected) {
                checkbox.checked = false
                setSelectedBills(prevValue => {
                    return []
                })
            }
        }
    }

    function selectCheckbox(e) {
        const val = e.target.value;
        // console.log(val)
        let ele = document.getElementById("selectAllCheckbox");
        ele.checked = true
        // console.log(ele);
       
        if (e.target.checked) {
            setSelectedBills(prevValue => {
                return [...prevValue, val]
            })
        } else {
            setSelectedBills(selectedBills.filter(item => {
                return item !== val
            }))
        }
        if (selectedBills.length <= 0){
            ele.checked = false
        }
    }

    const refreshBills = () => {
        window.location.reload()
    }

    // Search bill
    const searchBills = () => {
        if((searchDetails.cb_no === null || searchDetails.cb_no === '') && (searchDetails.iec_br === null || searchDetails.iec_br === '') && (searchDetails.startDate === null || searchDetails.endDate === '') && (searchDetails.endDate === null || searchDetails.startDate === '')){
            
            setEmailValidationMsg("Please fill required details");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 5000)

        } else{

            handleToggle();
            // console.log(searchDetails)
            const token = localStorage.getItem('token');
            let skip = 0;

            searchCourierBillsAPICall(token, searchDetails, skip).then(response => {
                if (response){
                    // console.log(response);
                    if (response.data){
                        if (response.data.data){
                            const totalRecords = response.data.count;
                            let pagesToShow = null;
                            if (totalRecords % 50 === 0) {
                                pagesToShow = Math.floor(totalRecords / 50);
                            } else {
                                pagesToShow = Math.floor(totalRecords / 50) + 1;
                            }
                            setPageCount(pagesToShow)
                            handleBackdropClose();
                            setBillsList(response.data.data);
                        } else{
                            setBillsList(response.data.data);
                            setEmailValidationMsg(response.data.msg);
                            setShowWarning({display: "inline-block"});
                            setTimeout(() => {
                                setShowWarning({display: "none"});
                            }, 5000)
                            handleBackdropClose();
                        }
                        setShowClear(true);

                    } else{
                        handleBackdropClose();
                        setEmailValidationMsg("Bill Not found");
                        setShowWarning({display: "inline-block"});
                        setTimeout(() => {
                            setShowWarning({display: "none"});
                        }, 5000)
                        // console.log("Not found");
                    }
                } else{
                    handleBackdropClose();
                }
            }, error => {
                console.log("error response: ", error.response)
                if (error.response){
                    if (error.response.status === 403){
                        localStorage.clear();
                        window.location.href = "/"
                    }
                }
                
            })
        }
    }

    const handleExportRangeSelect = (e) => {
        const value = e.target.value;
        if (value === "null"){
            const skipCount = -1;
            setExportRangeSelectSkipCount(skipCount);
            setExportRangeLimitCount(0);
            return null;
        }

        const skipCount = parseInt(e.target.value);
        setExportRangeSelectSkipCount(skipCount);
        setExportRangeLimitCount(500);
    }

    const handleRangeSelect = (e) => {
        const value = e.target.value;
        setExportRangeSelectSkipCount(-1);
        setExportRangeLimitCount(0);
        setExportRangeType(value);
    }

    const handleSkipInputChange = (e) => {
        const value = e.target.value;
        const skipCount = parseInt(value);
        setExportRangeSelectSkipCount(skipCount);
    }

    const handleLimitInputChange = (e) => {
        const value = e.target.value;
        const limitCount = parseInt(value);
        setExportRangeLimitCount(limitCount);
    }

    // Download a single bill
    const handleDownload = async (e) => {
        const value = e.target.title;
        const [cb_no, iec_no, cb_date] = value.split(";");
        const bill = {
            cb_no: cb_no,
            iec_no: iec_no,
            cb_date: cb_date
        }
        
        const downloadBillSchema = {
            "bills": [bill]
        }
        // Getting local storage token
        const token = localStorage.getItem('token');

        // API call
        handleToggle()
        downloadCourierBill(token, downloadBillSchema).then((response) => {
            
            // console.log(response);
            if (response){
                if(response.data){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = String(bill.cb_no) + ' ' + String(bill.cb_date) + '_output_flat_file.csv'
                    const excel_filename = String(bill.cb_no) + ' ' + String(bill.cb_date) + ' Output Flat File.xlsx'
                    link.setAttribute('download', excel_filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    handleBackdropClose()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                } else{
                    handleBackdropClose()
                }
                
            } else{
                handleBackdropClose()
            }
            
        }, error => {
            console.log("Error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }
            
        })

    }

    const downloadAll = () => {
        console.log("skip=", exportRangeSelectSkipCount, ", limit=", exportRangeLimitCount);
        const billType = "Courier";
        let limit = 500; // If staticRange
        const token = localStorage.getItem('token');

        if(exportRangeType == "dynamicRange"){

            if (exportRangeSelectSkipCount === -1 || exportRangeSelectSkipCount >= exportRangeLimitCount){
                return null;
            }
            limit = exportRangeLimitCount - exportRangeSelectSkipCount;
        }

        if(limit > 1000){
            setEmailValidationMsg("Cannot export more than 1000 bills at a time");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 5000)
            return null;
        }    

        handleToggle();
        downloadBillsWithLimit(token, exportRangeSelectSkipCount, limit, billType).then(response => {
            if (response){
                if (response.data){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const curr_date = new Date();
                    
                    const timestamp = String(curr_date.getDate()) + "-" + String(curr_date.getMonth()) + "-" + String(curr_date.getFullYear()) + "_" + String(curr_date.getHours()) + "." + String(curr_date.getMinutes()) + "." + String(curr_date.getSeconds());
                    const filename = 'Courier Output Flat File ' + String(timestamp) + '.xlsx'
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    handleBackdropClose()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                } else {
                    handleBackdropClose()
                }
            }
            else{
                handleBackdropClose()
            }
        }, error => {
            console.log("error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    // localStorage.clear();
                    // window.location.href = "/"
                }
            }  
        })
    }

    // Handle mutiple bills export
    const exportBills = async () => {
        
        const token = localStorage.getItem('token');
        var downloadBillSchema = {
            "bills": []
        };

        for (var i = 0; i < selectedBills.length; i++) {

            const selectedBill = selectedBills[i]
            const [cb_no, iec_no, cb_date] = selectedBill.split(";");
            const bill = {
                cb_no: cb_no,
                iec_no: iec_no,
                cb_date: cb_date
            }
            downloadBillSchema.bills.push(bill);
        }

        handleToggle();
        downloadCourierBill(token, downloadBillSchema).then((response) => {
            // console.log(response);
            if (response){
                if (response.data){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const curr_date = new Date();
                    
                    const timestamp = String(curr_date.getDate()) + "-" + String(curr_date.getMonth()) + "-" + String(curr_date.getFullYear()) + "_" + String(curr_date.getHours()) + "." + String(curr_date.getMinutes()) + "." + String(curr_date.getSeconds());
                    const filename = 'Courier Output Flat File ' + String(timestamp) + '.xlsx'
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    handleBackdropClose()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                } else {
                    handleBackdropClose()
                }
            }
            else{
                handleBackdropClose()
            }
        }, error => {
            console.log("error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }  
        })  
    }

    // sort by cb date
    const sortBySBDate = () => {
        setIsSort(prevValue => {
            return !prevValue
        });
        const copy = [...billsList]

        if (isSort){
            copy.sort((a, b) => {
                return new Date(b.cb_date).getTime() - new Date(a.cb_date).getTime()
            })
        } else{
            copy.sort((a, b) => {
                return new Date(a.cb_date).getTime() - new Date(b.cb_date).getTime()
            })
        }
        
        setBillsList(copy);
    }

    // sort by upload date
    const sortByUploadDate = () => {
        
        setIsSort(prevValue => {
            return !prevValue
        });
        const copy = [...billsList]

        if (isSort){
            copy.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            })
        } else{
            copy.sort((a, b) => {
                return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            })
        }
        
        setBillsList(copy);
    }

    // sort by IEC No.
    const sortByIEC = () => {
        
        const copy = [...billsList]
        copy.sort((a, b) => {
            return (Number)(a.file_iec_no) - (Number)(b.file_iec_no)
        })

        setBillsList(copy);
    }

    // Page change (Bills Listing)
    const handlePageChange = (e, val) => {
        const token = localStorage.getItem('token');
        setPage(val);
        const skip = (val * 50) - 50;
        searchCourierBillsAPICall(token, searchDetails, skip).then(response => {
            if (response){
                if(response.data){
                    // console.log(response);
                    setBillsList(response.data.data);
                }
            } 
        }, error => {
            console.log("Error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }
            
        })
        var checkboxes = document.getElementsByName('bill-checkboxes');
        var selectAllCheckbox = document.getElementById('selectAllCheckbox');
        selectAllCheckbox.checked = false;
        setSelectedBills(prevValue => {
            return []
        })
        for (var checkbox of checkboxes) {
            checkbox.checked = false;
        }
    }

    return (
        <div>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBackdrop}
                >
                    <Box
                        sx={{
                        top: 80,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                    >
                        <Typography style={{fontSize: "17px", fontWeight: "400", fontFamily: "Roboto", color: "#e7e7e7"}} variant="p" component="div" >
                            {"Please wait... This may take a while!"}
                        </Typography>
                    </Box>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            {/* <TableContainer style={{ minHeight: "90vh" }} component={Paper}> */}
            <TableContainer style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }} component={Paper}>

                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell >
                                <h4 className="outlet-heading">Courier </h4>
                                
                                <div className="search-div">
                                    
                                    <button className="search-btn" onClick={searchBills}><i className="fa-solid fa-magnifying-glass"></i></button>
                                    <div className="bills-search">
                                        <input onChange={handleInputChange} name="cb_no" placeholder='CB No.' className="be-input search-inp" type="text"></input>
                                        {getRole() === "superadmin" ? <input onChange={handleInputChange} name="iec_br" placeholder='IEC/Br' className="be-input search-inp date-inps-div" type="text"></input> : null}
                                        
                                        
                                        <span className="date-inps-div">
                                            <input onChange={handleInputChange} name="cb_date_start" title="CB Date - start" className="date-inp search-inp" type="date"></input> to
                                            <input onChange={handleInputChange} name="cb_date_end" title="CB Date - end" className="date-inp search-inp" type="date"></input>
                                        </span>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            <div id="uploadBar" className="upload-bar">

                {getRole() === "superadmin" ? null : <div>
                    <i style={{ marginRight: "4px", color: "rgb(51, 60, 131)" }} className="fa-solid fa-file-circle-plus"></i>
                    <input onChange={handleFileChange} className="file-input" type="file" accept='application/pdf' multiple></input>
                    <button title="Select a file and then upload" onClick={uploadFile} className="upload-btn">
                        <i style={{ marginRight: "5px" }} className="fa-solid fa-cloud-arrow-up"></i>
                        Upload
                    </button>
                </div>}

                <button className="delete-btn" onClick={removeBills} disabled={selectedBills.length > 0 ? false : true} title="Select some files" >Delete</button>
                {getRole() === "superadmin" ? 
                    <button className="delete-btn" disabled={billsList ? billsList.length > 0 ? false : true : true} onClick={removeAllBills} title="Delete All BOEs" >Delete All</button> 
                : null}
                
                {getRole() === "admin" ? 
                    <button className="delete-btn" disabled={billsList ? billsList.length > 0 ? false : true : true} onClick={removeBillsForAnIEC} title="Delete All SBs" >Delete All</button> 
                : null}
                <button className="upload-btn export-btn" onClick={exportBills} disabled={selectedBills.length > 0 ? false : true} title="Select some files" ><i className="fa-solid fa-arrow-down"></i> Download</button>

                <div >
                    <select  id="rangeSelectType" onChange={handleRangeSelect} className="export-btn">
                        <option style={{color: "grey"}} value="null">Select Courier Type</option>
                        <option value="staticRange">Static Range</option>
                        <option value="dynamicRange">Dynamic Range</option>
                    </select>
                </div>
  
                <div style={exportRangeType == "staticRange" ? {display: "inline-block"} : {display: "none"}}>
                    &nbsp;&nbsp;&nbsp;
                    <select id="exportRangeSelect" onChange={handleExportRangeSelect} className="delete-btn">
                        <option value="null">Select</option>
                    </select>
                </div>

                {exportRangeType == "dynamicRange" ? <div style={{display: "inline"}}>
                    &nbsp;&nbsp;
                    from:&nbsp;<input onChange={handleSkipInputChange} style={{width: "70px"}} className="export-btn" placeholder="from" type="number"></input>&nbsp;
                    to:&nbsp;<input onChange={handleLimitInputChange} style={{width: "70px"}} className="export-btn" placeholder="to" type="number"></input>
                    
                </div> : null}
                &nbsp;&nbsp;&nbsp;

                <button className="upload-btn export-btn" onClick={downloadAll} title="Download All" ><i className="fa-solid fa-download"></i> Export </button>
                <ResponsiveDialog open={open} type="BILL" handleClose={handleClose} />
                <PasswordDialogBox open={passwordBoxOpen} handlePasswordInputChange={handlePasswordInputChange} handlePasswordDialogBoxClose={handlePasswordDialogBoxClose} />
                <CheckboxConfirmationDialogBox open={checkboxConfirmationBoxOpen} handleCheckboxInputChange={handleCheckboxInputChange} handleCheckboxConfirmationDialogBoxClose={handleCheckboxConfirmationDialogBoxClose} />
                <BasicAlert style={showWarning} message={emailValidationMsg} />
                <span onClick={refreshBills} className="clear-search" style={showClear ? {display: "inline-block"} : {display: "none"}}><i className="fa-solid fa-xmark"></i> clear search</span>
            </div>

            <TableContainer style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }} component={Paper}>

                <Table sx={{ minWidth: 300 }} aria-label="simple table">

                    <TableHead >

                        <TableRow style={{ backgroundColor: "#f8f9fa" }}>
                            <TableCell id="remv-headings" align="left"><label className="main"><input id="selectAllCheckbox" title="Select all" onChange={(e) => handleSelect(e)} type="checkbox" ></input><span className="geekmark"></span></label></TableCell>
                            <TableCell align="left"><h6>No</h6></TableCell>
                            <TableCell align="left"><h6>File Name</h6></TableCell>
                            <TableCell align="left"><h6>CB No</h6></TableCell>
                            <TableCell align="left"><h6 style={{margin: "0"}}>CB Date <i onClick={sortBySBDate} title="Sort by sb date" className="fa-solid fa-sort sort-btn"></i><p style={{margin: "0", fontSize: "12px", fontWeight: "500"}}>(yyyy/mm/dd)</p></h6></TableCell>
                            {getRole() === "superadmin" ?
                            <TableCell align="left"><h6>IEC/Br <i onClick={sortByIEC} title="Sort by IEC no." className="fa-solid fa-sort sort-btn"></i></h6></TableCell>
                            : null}
                            
                            {/* <TableCell align="left"><h6>Br</h6></TableCell> */}
                            <TableCell id="remv-headings" align="left"><h6>Upload Date <i onClick={sortByUploadDate} title="Sort by upload date" className="fa-solid fa-sort sort-btn"></i></h6></TableCell>
                            <TableCell style={{ paddingRight: "34px" }} align="right"><h6>CSV</h6></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(billsList ? billsList.map((row, index, arr) => (
                                <TableRow
                                    key={index + 1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell id="remv-headings" align="left"><input name="bill-checkboxes" value={[row.cb_no + ";" + row.file_iec_no + ";" + row.cb_date + ";" + row.pdf_filename]} onChange={(e) => { selectCheckbox(e) }} style={{ width: "20px" }} type="checkbox"></input> </TableCell>
                                    <TableCell > {index + 1 + (page * 50 - 50)} </TableCell>
                                    <TableCell align="left"><i style={{color: "tomato"}} className="fa-solid fa-file-pdf"></i> {String(row.pdf_filename).split("_")[0] + ".pdf"}</TableCell>
                                    <TableCell align="left"><span style={{ backgroundColor: "#FFF6EA", padding: "4px", borderRadius: "4px", fontSize: "16px" }}>{row.cb_no}</span></TableCell>
                                    <TableCell align="left"><CalendarTodayIcon style={{ width: "20px" }} /> {row.cb_date}</TableCell>
                                    {getRole() === "superadmin" ? 
                                    <TableCell style={{ color: "#333C83", fontSize: "16px" }} align="left"><strong>{row.file_iec_no + "/" + row.br}</strong></TableCell>
                                    : null}
                                    
                                    {/* <TableCell id="remv-headings" align="left">{String(String(new Date(row.created_at))).split("GMT")[0].slice(3)}</TableCell> */}
                                    <TableCell id="remv-headings" align="left">{String(new Date(new Date(row.created_at).getTime() + (330*60000))).split("GMT")[0].slice(3)}</TableCell>
                                    <TableCell style={{ paddingRight: "36px" }} align="right">
    
                                        <span title="Download file" className="download-btn">
                                            <i title={row.cb_no + ";" + row.file_iec_no + ";" + row.cb_date} onClick={handleDownload} className="fa-solid fa-file-arrow-down fa-2x" ></i>
                                        </span>
    
                                    </TableCell>
                                </TableRow>
                            )) : null) }
                        
                    </TableBody>
                </Table>

            </TableContainer>
            <Box sx={{ width: '100%' }} style={openProgress ? {display: "block"} : {display: "none"}}>
                <LinearProgress />
            </Box>
            <div className="pagination-stack">
                <Stack spacing={2}>
                    <Pagination count={pageCount} page={page} shape="rounded" onChange={handlePageChange} />
                </Stack>
            </div>

        </div>
    );
}